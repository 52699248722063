@import 'fonts';
@import 'colors';
@import 'responsive';

.container-partner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
        margin: 0;
        margin-bottom: 2.5vh;
        padding: 3vh 6vw;
        @include font-bold;
        font-size: 33px;
        text-align: center;

        border: 2px solid white;
        border-radius: 5vw;
        background-color: transparent;
    }

    h3 {
        @include font-regular;
        text-align: center;
        font-size: 20px;
    }
}
