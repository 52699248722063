@import 'fonts';
@import 'colors';
@import 'responsive';

.container-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 7vh;
    padding-bottom: 2vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background: rgb(2, 0, 36);
    background: linear-gradient(
        180deg,
        rgba(5, 4, 20, 0) 0%,
        rgba(5, 4, 20, 1) 50%
    );

    .footer-link {
        margin: 0 2vw;
        @include font-regular;
        font-size: 0.6vw;
        color: rgba(255, 255, 255, 0.25);

        &:hover:not(.copyright) {
            color: rgba(255, 255, 255, 0.5);
        }

        @include mobile() {
            font-size: 2.6vw;
        }
    }
}
