@import 'fonts';
@import 'colors';
@import 'responsive';

.container-code {
    width: 50vw;
    margin: auto;
    padding: 15vh 0;
    @include font-regular;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $secondary;

    @include mobile() {
        flex-direction: column;
    }

    h1 {
        margin-bottom: 3vh;
        @include font-bold;
        font-size: 2.2vw;
        text-align: center;

        @include mobile() {
            font-size: 6.2vw;
        }
    }

    .input {
        padding: 10px 15px;
        border: 2px solid #fff;
        background-color: transparent;
        border-radius: 10px;
        color: #fff;
        outline: none;

        @include mobile() {
            width: 100% !important;
            margin-bottom: 1.5vh;
            border-radius: 10px !important;
            border: 2px solid #fff !important;
        }
    }

    .ipt-code {
        width: 30vw;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    .ipt-generate {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        cursor: pointer;
    }

    .container-type {
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: space-evenly;

        @include mobile() {
            flex-direction: column;
            margin-top: 0px;

            .ipt-radio {
                padding: 5px 15px !important;

                input {
                    width: auto !important;
                    margin-right: 5px;
                    margin-bottom: 0 !important;
                }
            }
        }
    }

    .ipt-date {
        margin-top: 15px;

        @include mobile() {
            margin-top: 0;
        }
    }

    .ipt-submit {
        margin-top: 15px;
        cursor: pointer;
    }

    .ipt-full {
        width: 50%;
        margin-bottom: 15px;
    }
}
