@import 'fonts';
@import 'colors';
@import 'responsive';

.container-policy {
    width: 50vw;
    margin: auto;
    padding: 15vh 0;
    @include font-regular;
    color: $secondary;

    @include mobile() {
        width: 80vw;
    }

    h1 {
        margin-bottom: 3vh;
        @include font-bold;
        font-size: 2.2vw;

        @include mobile() {
            margin-bottom: 5vh;
            font-size: 6.2vw;
        }
    }

    h2 {
        margin-bottom: 4vh;
        @include font-bold;
        font-size: 1.2vw;

        @include mobile() {
            margin-bottom: 4vh;
            font-size: 5.2vw;
        }
    }

    h3 {
        @include mobile() {
            margin-bottom: 2vh;
        }
    }

    p {
        margin-bottom: 3vh;

        @include mobile() {
            font-size: 3vw;
        }
    }
}
