@import 'colors';
@import 'responsive';

.container-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .logo-header {
        width: 16vw;
        margin-top: 2.5vh;

        @include mobile() {
            width: 50vw;
            margin-top: 5vh;
        }
    }
}
