@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after{content:'';content:none}q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}*,body{box-sizing:border-box}

.container-body{position:relative;min-height:100vh;display:flex;justify-items:center;align-items:center;background-image:url(/static/media/background.d409a57a.svg);background-size:cover;background-position:center center;background-attachment:fixed}

.container-home{width:100%;height:100vh;padding-bottom:9.5vh;display:flex;flex-direction:column;justify-content:center;align-items:center}.container-home .logo-home{width:26vw;margin-bottom:1vh}@media screen and (max-width: 400px){.container-home .logo-home{width:60vw}}.container-home .subTitle{margin-bottom:5vh;font-family:'Play', sans-serif;font-weight:400;color:#fff;text-decoration:none;transition:all 0.3;line-height:auto;font-size:2.5vw;text-align:center}@media screen and (max-width: 400px){.container-home .subTitle{margin-bottom:2.5vh;font-size:7vw}}.container-home .container-badges .badge{width:auto;height:6vw;margin:0 1vw}@media screen and (max-width: 400px){.container-home .container-badges .badge{height:13vw}}

.container-terms{width:50vw;margin:auto;padding:15vh 0;font-family:'Play', sans-serif;font-weight:400;color:#fff;text-decoration:none;transition:all 0.3;line-height:auto;color:#cfceda}.container-terms h1{margin-bottom:3vh;font-family:'Play', sans-serif;font-weight:700;color:#fff;text-decoration:none;transition:all 0.3;font-size:2.2vw}@media screen and (max-width: 400px){.container-terms h1{font-size:6.2vw}}.container-terms h2{margin-bottom:2vh;font-family:'Play', sans-serif;font-weight:700;color:#fff;text-decoration:none;transition:all 0.3;font-size:1.2vw}@media screen and (max-width: 400px){.container-terms h2{margin-bottom:4vh;font-size:5.2vw}}.container-terms p{margin-bottom:3vh}@media screen and (max-width: 400px){.container-terms p{font-size:3vw}}

.container-header{position:fixed;top:0;left:0;right:0;width:100vw;height:10vh;display:flex;justify-content:center;align-items:center;z-index:999}.container-header .logo-header{width:16vw;margin-top:2.5vh}@media screen and (max-width: 400px){.container-header .logo-header{width:50vw;margin-top:5vh}}

.container-policy{width:50vw;margin:auto;padding:15vh 0;font-family:'Play', sans-serif;font-weight:400;color:#fff;text-decoration:none;transition:all 0.3;line-height:auto;color:#cfceda}@media screen and (max-width: 400px){.container-policy{width:80vw}}.container-policy h1{margin-bottom:3vh;font-family:'Play', sans-serif;font-weight:700;color:#fff;text-decoration:none;transition:all 0.3;font-size:2.2vw}@media screen and (max-width: 400px){.container-policy h1{margin-bottom:5vh;font-size:6.2vw}}.container-policy h2{margin-bottom:4vh;font-family:'Play', sans-serif;font-weight:700;color:#fff;text-decoration:none;transition:all 0.3;font-size:1.2vw}@media screen and (max-width: 400px){.container-policy h2{margin-bottom:4vh;font-size:5.2vw}}@media screen and (max-width: 400px){.container-policy h3{margin-bottom:2vh}}.container-policy p{margin-bottom:3vh}@media screen and (max-width: 400px){.container-policy p{font-size:3vw}}

.container-terms{width:50vw;margin:auto;padding:15vh 0;font-family:'Play', sans-serif;font-weight:400;color:#fff;text-decoration:none;transition:all 0.3;line-height:auto;color:#cfceda}.container-terms h1{margin-bottom:3vh;font-family:'Play', sans-serif;font-weight:700;color:#fff;text-decoration:none;transition:all 0.3;font-size:2.2vw;text-align:center}@media screen and (max-width: 400px){.container-terms h1{font-size:6.2vw}}

.container-code{width:50vw;margin:auto;padding:15vh 0;font-family:'Play', sans-serif;font-weight:400;color:#fff;text-decoration:none;transition:all 0.3;line-height:auto;display:flex;flex-direction:column;align-items:center;color:#cfceda}@media screen and (max-width: 400px){.container-code{flex-direction:column}}.container-code h1{margin-bottom:3vh;font-family:'Play', sans-serif;font-weight:700;color:#fff;text-decoration:none;transition:all 0.3;font-size:2.2vw;text-align:center}@media screen and (max-width: 400px){.container-code h1{font-size:6.2vw}}.container-code .input{padding:10px 15px;border:2px solid #fff;background-color:transparent;border-radius:10px;color:#fff;outline:none}@media screen and (max-width: 400px){.container-code .input{width:100% !important;margin-bottom:1.5vh;border-radius:10px !important;border:2px solid #fff !important}}.container-code .ipt-code{width:30vw;border-top-right-radius:0;border-bottom-right-radius:0;border-right:none}.container-code .ipt-generate{border-top-left-radius:0;border-bottom-left-radius:0;cursor:pointer}.container-code .container-type{width:100%;margin-top:15px;display:flex;justify-content:space-evenly}@media screen and (max-width: 400px){.container-code .container-type{flex-direction:column;margin-top:0px}.container-code .container-type .ipt-radio{padding:5px 15px !important}.container-code .container-type .ipt-radio input{width:auto !important;margin-right:5px;margin-bottom:0 !important}}.container-code .ipt-date{margin-top:15px}@media screen and (max-width: 400px){.container-code .ipt-date{margin-top:0}}.container-code .ipt-submit{margin-top:15px;cursor:pointer}.container-code .ipt-full{width:50%;margin-bottom:15px}

.container-partner{width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.container-partner button{margin:0;margin-bottom:2.5vh;padding:3vh 6vw;font-family:'Play', sans-serif;font-weight:700;color:#fff;text-decoration:none;transition:all 0.3;font-size:33px;text-align:center;border:2px solid white;border-radius:5vw;background-color:transparent}.container-partner h3{font-family:'Play', sans-serif;font-weight:400;color:#fff;text-decoration:none;transition:all 0.3;line-height:auto;text-align:center;font-size:20px}

.container-footer{position:fixed;bottom:0;left:0;right:0;height:7vh;padding-bottom:2vh;display:flex;align-items:flex-end;justify-content:center;background:#020024;background:linear-gradient(180deg, rgba(5,4,20,0) 0%, #050414 50%)}.container-footer .footer-link{margin:0 2vw;font-family:'Play', sans-serif;font-weight:400;color:#fff;text-decoration:none;transition:all 0.3;line-height:auto;font-size:0.6vw;color:rgba(255,255,255,0.25)}.container-footer .footer-link:hover:not(.copyright){color:rgba(255,255,255,0.5)}@media screen and (max-width: 400px){.container-footer .footer-link{font-size:2.6vw}}

