@import 'responsive';
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

@mixin font {
    color: #fff;
    // text-transform: uppercase;
    text-decoration: none;

    transition: all 0.3;
}

@mixin font-regular {
    font-family: 'Play', sans-serif;
    font-weight: 400;
    @include font;
    line-height: auto;
}

@mixin font-bold {
    font-family: 'Play', sans-serif;
    font-weight: 700;
    @include font;
}
