@import 'fonts';
@import 'responsive';

.container-home {
    width: 100%;
    height: 100vh;
    padding-bottom: 9.5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo-home {
        width: 26vw;
        margin-bottom: 1vh;

        @include mobile() {
            width: 60vw;
        }
    }

    .subTitle {
        margin-bottom: 5vh;
        @include font-regular;
        font-size: 2.5vw;
        text-align: center;

        @include mobile() {
            // width: 70vw;
            margin-bottom: 2.5vh;
            font-size: 7vw;
        }
    }

    .container-badges {
        .badge {
            width: auto;
            height: 6vw;
            margin: 0 1vw;

            @include mobile() {
                height: 13vw;
            }
        }
    }
}
