@import 'fonts';
@import 'colors';
@import 'responsive';

.container-terms {
    width: 50vw;
    margin: auto;
    padding: 15vh 0;
    @include font-regular;
    color: $secondary;

    h1 {
        margin-bottom: 3vh;
        @include font-bold;
        font-size: 2.2vw;
        text-align: center;

        @include mobile() {
            font-size: 6.2vw;
        }
    }
}
